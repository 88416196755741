import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styles from '../styles/variables.scss'
import ItemListing from '../components/item-listing'
import Card from '../components/card'
import styled from 'styled-components'
import ServiceHero from '../components/service-hero'
import Reputation from '../components/reputation'
import CTA from '../components/cta-bar'

class ServiceCityTemplate extends Component {
  render() {
    let { pageContext } = this.props
    const city = pageContext.city
    let pageContent = this.props.data.contentfulServiceAreaContent

    console.log('Service City Template Props', this.props)
    console.log('Service', pageContent.serviceTitle)

    return (
      <Layout>
        <SEO title={pageContent.serviceTitle.replace('{{city}}', city)} />
        <p>{pageContent.serviceTitle.replace('{{city}}', city)}</p>
      </Layout>
    )
  }
}

export default ServiceCityTemplate

export const pageQuery = graphql`
  query serviceCityQuery($slug: String!) {
    contentfulServiceAreaContent(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      slug
    }
  }
`
